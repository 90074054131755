import SectionHeader from '../components/sections/header';
import SectionBannerSlider from '../components/sections/banner-slider';
import SectionMenuTiles from '../components/sections/menu-tiles';
import SectionProductSlider from '../components/sections/product-slider';
import SectionManufacturerSlider from '../components/sections/manufacturer-slider';
import SectionCta from '../components/sections/cta';
import SectionInstagram from '../components/sections/instagram';
import SectionPostSlider from '../components/sections/post-slider';
import SectionArticlePreview from '../components/sections/article-preview';
import SectionNewsletter from '../components/sections/newsletter';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql, Script } from 'gatsby';

import { IPage } from '../models/page.model';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

import { structuredGeneralData, structuredLocalBusinessData } from '../config/structured-data';

const structuredGeneralDataString = JSON.stringify(structuredGeneralData);
const structuredLocalBusinessDataString = JSON.stringify(structuredLocalBusinessData);

interface IIndexProps {
    readonly data: {
        page: IPage;
    };
}

const Index: React.FC<IIndexProps> = ({ data }) => {
    const { page } = data;

    return (
        <MainLayout>
            <Script type="application/ld+json">{structuredGeneralDataString}</Script>
            <Script type="application/ld+json">{structuredLocalBusinessDataString}</Script>
            {page.sections.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={section.isMain ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default Index;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'banner-slider': SectionBannerSlider,
    'menu-tiles': SectionMenuTiles,
    'product-slider': SectionProductSlider,
    'manufacturer-slider': SectionManufacturerSlider,
    'cta': SectionCta,
    'instagram': SectionInstagram,
    'post-slider': SectionPostSlider,
    'article-preview': SectionArticlePreview,
    'newsletter': SectionNewsletter,
    'footer': SectionFooter,
};